import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
	<Layout fullMenu>
		<article id="main">
			<header>
				<h2>Контакты</h2>
			</header>
			<section className="wrapper style4 special">
				<div className="inner">
					<p>
						Пожалуйста заполните контактную форму и мы обязательно свяжемся с Вами в ближайшее время.
					</p><br />
					<section>
						<form method="post" action="https://getform.io/f/0104db49-2053-43f1-a608-b809d5406f9b">
							<div className="row gtr-uniform">
								<div className="col-6 col-12-xsmall">
									<input type="text" name="name" placeholder="Имя" required="required" />
								</div>
								<div className="col-6 col-12-xsmall">
									<input type="email" name="email" placeholder="Ваш Email" required="required" />
								</div>
								<div className="col-12">
									<textarea name="message" placeholder="Ваше сообщение" rows="6" required="required" ></textarea>
								</div>
								<div className="col-12">
									<ul className="actions">
										<li><input type="submit" value="Отправить" className="primary" /></li>
									</ul>
								</div>
							</div>
						</form>
					</section>
				</div>
			</section>
		</article>
	</Layout>
);

export default IndexPage;
